import React, { useState, useEffect } from "react";
import "./cases-filter.component.scoped.scss";
import { CasesService } from "../../services/cases/cases.service";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Popover,
  Typography,
  Box,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FilterList, Add } from "@material-ui/icons";
import { periodsRange } from "../../statics/periods";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { AIRolesList } from "../../modules/case/types/AI-roles";

export type TableFilter = {
  field: string; // field key
  label: string; // field label
  value: string; // search value
};
const emptySearchObj = {
  label: "",
  searchId: "",
};

export default function CasesFilterComponent(props: any) {
  const { searchableFields, onFiltersChange } = props;
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const [filters, setFilters] = useState<TableFilter[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchObj, setSearchObj] = useState(searchableFields[0]);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);

  const [filterList, setFilterList]: any = useState({
    statusId: [],
    branchId: [],
    categoryId: [],
    assigneeId: [],
    period: periodsRange,
    role: [],
  });

  const fetchLookupsData = async () => {
    const statusRes = await casesService.listStatuses();
    const categoriesRes = await casesService.listCategories();
    const branchesRes = await casesService.listBranches();
    const usersRes = await casesService.listClerks({});
    usersRes.unshift({ id: "all", firstName: t("all"), lastName: "" });
    statusRes.unshift({ id: "all", name: t("all") });
    categoriesRes.unshift({ id: "all", name: t("all") });
    branchesRes.unshift({ id: "all", name: t("all") });

    setFilterList({
      ...filterList,
      statusId: [...statusRes],
      branchId: [...branchesRes],
      categoryId: [...categoriesRes],
      assigneeId: [...usersRes],
      role: [...AIRolesList],
    });
  };

  const addFilter = async () => {
    if (searchValue) {
      // set new filters
      const payload = [
        ...filters,
        {
          field: searchObj.searchId,
          label: searchObj.label,
          value: searchValue,
        },
      ];
      setFilters(payload);
      onFilter(payload);
      // selected filters for disabled the used filters
      const newSelectedFilterIds = [...selectedFilterIds, searchObj.searchId];
      setSelectedFilterIds([...newSelectedFilterIds]);
      // clear search text
      setSearchValue("");
      // set next filter dropdown value from fields
      setSearchObj(
        searchableFields.find(
          (field: any) => !newSelectedFilterIds.includes(field.searchId)
        ) || emptySearchObj
      );
    }
  };

  const onFilter = (filters: TableFilter[]) => {
    let payload: { [key: string]: string } = {};
    filters.map((filter) => {
      if (filter.field === "period") {
        const periodRange = periodsRange.find(
          (item) => item.id === filter.value
        ).value;
        payload = { ...payload, ...periodRange };
      } else {
        payload[filter.field] = filter.value;
      }
      return true;
    });

    onFiltersChange(payload);
  };

  const removeFilter = async (value: any) => {
    // REMOVE FILTER
    setFilters([...filters.filter((filter) => filter.field !== value.field)]);
    // selected filters for hide the used filters
    const newSelectedFilterIds = selectedFilterIds.filter(
      (selectedId) => selectedId !== value.field
    );
    setSelectedFilterIds([...newSelectedFilterIds]);
    // set next filter dropdown value from fields if all filters used and removed one of them.
    if (!searchObj || !searchObj.serchId) {
      setSearchObj(
        searchableFields.find(
          (field: any) => !newSelectedFilterIds.includes(field.searchId)
        )
      );
    }
  };

  const handleFilterChange = (event: any) => {
    setSearchValue("");
    setSearchObj(
      searchableFields.find(
        (field: any) => field.searchId === event.target.value
      )
    );
  };

  useEffect(() => {
    fetchLookupsData();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   let payload: { [key: string]: string } = {};

  //   filters.map((filter) => {
  //     if (filter.field === "period") {
  //       const periodRange = periodsRange.find(
  //         (item) => item.id === filter.value
  //       ).value;
  //       payload = { ...payload, ...periodRange };
  //     } else {
  //       payload[filter.field] = filter.value;
  //     }
  //     return true;
  //   });

  //   onFiltersChange(payload);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters]);

  return (
    <div className="cases-filters">
      <div className="filter-btn-container">
        <Button
          aria-describedby={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }
          size="small"
          className="filter-btn"
        >
          <FilterList />
          {/* {t("filters")} */}
        </Button>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="filter-content p-3"
          style={{ width: "600px", maxWidth: "600px" }}
        >
          {filters.map((filter: any) => {
            return (
              <Box className="filter-item mb-3" key={filter.field}>
                <FormControl className="select-input w-100">
                  <InputLabel id={filter.searchId}>{filter.label}</InputLabel>
                  <Select
                    labelId={filter.field}
                    id={filter.field}
                    value={filter.value}
                    name={filter.field}
                    disabled
                    IconComponent={ExpandMoreIcon}
                  >
                    {[...(filterList[filter.field] || [])].map(
                      (option: any, index: number) => (
                        <MenuItem key={index} value={option.id}>
                          {filter.field === "period" || filter.field === "role"
                            ? t(option.name)
                            : filter.field === "assigneeId"
                            ? option.firstName + " " + option.lastName
                            : option.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl className="select-input select-grey w-100">
                  <InputLabel id={filter.searchId}>{filter.label}</InputLabel>
                  <Select
                    labelId={filter.searchId}
                    id={filter.searchId}
                    value={filter.searchId}
                    name={filter.searchId}
                    IconComponent={ExpandMoreIcon}
                    disabled
                  >
                    {searchableFields.map((option: any) => (
                      <MenuItem key={option.searchId} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box
                  onClick={() => removeFilter(filter)}
                  className="remove-filter"
                >
                  <DeleteOutlineIcon />
                </Box>
              </Box>
            );
          })}

          {filters.length !== searchableFields.length && (
            <>
              <Box className="filter-item">
                <FormControl className="select-input w-100">
                  <InputLabel id={searchObj.label}>
                    {t("SELECT_VALUE")}
                  </InputLabel>
                  <Select
                    labelId={searchObj.label}
                    id={searchObj.label}
                    value={searchValue}
                    name={searchObj.label}
                    onChange={(event: any) =>
                      setSearchValue(event.target.value)
                    }
                    IconComponent={ExpandMoreIcon}
                  >
                    {[...(filterList[searchObj.searchId] || [])].map(
                      (item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {/* {item.name} */}
                          {searchObj.searchId === "period" ||
                          searchObj.searchId === "role"
                            ? t(item.name)
                            : searchObj.searchId === "assigneeId"
                            ? item.firstName + " " + item.lastName
                            : item.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl className="select-input select-grey w-100">
                  <InputLabel id={searchObj.searchId}>
                    {searchObj.label}
                  </InputLabel>
                  <Select
                    labelId={searchObj.label}
                    id={searchObj.searchId}
                    value={searchObj.searchId}
                    name={searchObj.searchId}
                    onChange={handleFilterChange}
                    IconComponent={ExpandMoreIcon}
                  >
                    {searchableFields.map(
                      (option: any) =>
                        !selectedFilterIds.includes(option.searchId) && (
                          <MenuItem
                            key={option.searchId}
                            value={option.searchId}
                          >
                            {t(option.label)}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}

          <Box
            display="flex"
            alignItems="center"
            marginTop={3}
            gridGap={10}
            style={{ cursor: "pointer" }}
            onClick={addFilter}
          >
            <Box className="cyrcle-icon">
              <Add />
            </Box>
            <Typography>{t("ADD_FILTER")}</Typography>
          </Box>
        </div>
      </Popover>
    </div>
  );
}
