import React, { useEffect, useState } from "react";
import { icons } from "../../../../statics/icons";
import "./todo-row.component.scoped.scss";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { TodoService } from "../../../../services/todo/todo.service";
import { showToastMsg } from "../../../../helpers";
import { Box, Button, Typography } from "@material-ui/core";

const TodoRowComponent = (props: any) => {
  const { refresh, data } = props;
  const [t] = useTranslation();
  const todoService = new TodoService();
  const [userImageError, setUserImageError] = useState(false);
  const [responsibleImageError, setResponsibleImageError] = useState(false);
  const [finishedUserImageError, setFinishedUserImageError] = useState(false);
  const [todo, setTodo] = useState(data);
  const [dueDays, setDueDays] = useState(
    moment(data.deadline).diff(moment(new Date()), "days")
  );

  const completeTask = async () => {
    const response = await todoService.update(todo.id, { status: true });
    if (response === "Success") {
      showToastMsg("success", t("task completed successfully"));
    } else {
      showToastMsg("error", t("some thing went wrong"));
    }
    refresh();
  };

  useEffect(() => {
    setTodo(props.data);
  }, [props.data]);

  return (
    <Box className="content mb-4">
      <Box className={"tag " + (todo.status ? "complete" : "pending")}>
        {todo.status ? t("task completed") : t("task pending")}
      </Box>

      <Box className="todo-content">
        <Box className="left-section">
          <Box className="item">
            <Typography variant="body1">{t("QUESTION")}</Typography>
            <Box className="d-grid" style={{ gap: "1rem" }}>
              <Box className="icon-wrap">
                <img src={icons.noteAddLight} alt="TODO" />
              </Box>
              <Typography variant="h4">{todo.question}</Typography>
            </Box>
          </Box>
          <Box className="item mt-4">
            <Typography variant="body1">{t("TASK")}</Typography>
            <Typography variant="h4">{todo.task}</Typography>
          </Box>
        </Box>
        <Box className="right-section">
          <Box className="item">
            <Typography variant="body1">{t("CREATED")}</Typography>
            <Box className="d-grid" style={{ gap: ".4rem" }}>
              <Box className="profile-pic">
                {todo.user.profilePicURL && !userImageError ? (
                  <img
                    src={todo.user.profilePicURL || icons.PLACEHOLDER.user}
                    title={todo.user.firstName + " " + todo.user.lastName}
                    alt=""
                    onError={setUserImageError.bind(null, true)}
                  />
                ) : (
                  <span className="text-uppercase">
                    {todo.user.firstName
                      ? todo.user.firstName[0] + todo.user.lastName[0]
                      : ""}
                  </span>
                )}
              </Box>
              <Typography variant="h5">
                {todo.user
                  ? todo.user.firstName + " " + todo.user.lastName
                  : "Anonymous"}
              </Typography>
            </Box>
          </Box>
          <Box className="item mt-3">
            <Typography variant="body1">{t("RESPONSIBLE")}</Typography>
            <Box className="d-grid" style={{ gap: ".4rem" }}>
              <Box className="profile-pic">
                {todo.responsible.profilePicURL && !responsibleImageError ? (
                  <img
                    src={
                      todo.responsible.profilePicURL || icons.PLACEHOLDER.user
                    }
                    title={
                      todo.responsible.firstName +
                      " " +
                      todo.responsible.lastName
                    }
                    alt=""
                    onError={setResponsibleImageError.bind(null, true)}
                  />
                ) : (
                  <span className="text-uppercase">
                    {todo.responsible.firstName
                      ? todo.responsible.firstName[0] +
                        todo.responsible.lastName[0]
                      : ""}
                  </span>
                )}
              </Box>
              <Typography variant="h5">
                {todo.responsible
                  ? todo.responsible.firstName + " " + todo.responsible.lastName
                  : "Anonymous"}
              </Typography>
            </Box>
          </Box>
          <Box className="item mt-3">
            <Typography variant="body1">{t("DUE_DATE")}</Typography>
            <Typography variant="h6">
              {moment(todo.deadline).format("DD.MM.YYYY")}
            </Typography>
          </Box>
          {todo.status === 0 && (
            <Box className="item mt-1">
              <Typography variant="body1"></Typography>
              <Typography
                variant="h6"
                className={`${dueDays <= 5 && "due-date"}`}
              >
                <Trans i18nKey="DUE_IN_DAYS" values={{ days: dueDays }}></Trans>
              </Typography>
            </Box>
          )}
          {todo.finishedBy && (
            <Box className="item mt-3">
              <Typography variant="body1">{t("DONE_BY")}</Typography>
              <Box className="d-grid" style={{ gap: ".4rem" }}>
                <Box className="profile-pic">
                  {todo.user.profilePicURL && !finishedUserImageError ? (
                    <img
                      src={todo.user.profilePicURL || icons.PLACEHOLDER.user}
                      title={todo.user.firstName + " " + todo.user.lastName}
                      alt=""
                      onError={setFinishedUserImageError.bind(null, true)}
                    />
                  ) : (
                    <span className="text-uppercase">
                      {todo.user.firstName
                        ? todo.user.firstName[0] + todo.user.lastName[0]
                        : ""}
                    </span>
                  )}
                </Box>
                <Typography variant="h5">
                  {todo.finishedBy.firstName + " " + todo.finishedBy.lastName}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {todo.status === 0 && (
        <Box className="text-right mt-4">
          <Button
            className="btn-primary-theme"
            variant="contained"
            size="small"
            color="primary"
            onClick={completeTask}
          >
            <img src={icons.checkWhite} alt="" width={20} className="mr-2" />
            {t("COMPLETE_TASK")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TodoRowComponent;

// <Box className="top-row">
//           <Box className="info">
//             <span>{t("case")}:</span>
//             <span className="link" onClick={openCase}>
//               {internalId}
//             </span>
//           </Box>
// <h6>{question}</h6>
//           {status === 0 && (
//             <Box className="action">
//               <Box className="icon-wrap">
//                 <img src={icons.check} onClick={completeTask} alt="edit" />
//               </Box>
//             </Box>
//           )}
//         </Box>
//         <Box className="bottom-row">
//           {status === 0 ? (
//             <Box className="dates">
//               <p className="start-date">
//                 {moment(created_at).format("DD.MM.YYYY")}
//               </p>
//               <span>
//                 {Math.ceil(
//                   (new Date(deadlineDate).getTime() -
//                     new Date(created_at).getTime()) /
//                     (1000 * 3600 * 24)
//                 )}{" "}
//                 {t("days")}
//               </span>
//               <Box className="end-date">
//                 {moment(deadlineDate).format("DD.MM.YYYY")}
//                 <Box>
//                   <img
//                     src={icons.edit}
//                     alt="edit"
//                     onClick={() => setOpen(dateChanged ? false : true)}
//                   />
//                   <Box className="calender">
//                     <MuiPickersUtilsProvider
//                       utils={MomentUtils}
//                       libInstance={moment}
//                       locale={locale}
//                     >
//                       <KeyboardDatePicker
//                         disableToolbar
//                         disabled={dateChanged}
//                         variant="inline"
//                         inputVariant="filled"
//                         fullWidth
//                         format="DD/MM/YYYY"
//                         autoOk
//                         open={open}
//                         onOpen={() => setOpen(true)}
//                         onClose={() => setOpen(false)}
//                         value={deadlineDate}
//                         minDate={created_at ? created_at : undefined}
//                         onChange={(date: any): any =>
//                           onDeadlineChangeHandler(date)
//                         }
//                       />
//                     </MuiPickersUtilsProvider>
//                   </Box>
//                 </Box>
//               </Box>
//             </Box>
//           ) : (
//             <Box>
//               <Box className="info">
//                 <span>{t("responsible person-s")}</span>
//                 <p className="text-capitalize">
//                   {user ? user.firstName + " " + user.lastName : "Anonymous"}
//                 </p>
//               </Box>
//               <Box className="date mt-2">
//                 <span>{t("created on")}</span>
//                 <p>{moment(created_at).format("DD.MM.YYYY")}</p>
//               </Box>
//             </Box>
//           )}
//           <p className="task">{task}</p>
//           {status === 0 ? (
//             <Box className="d-flex justify-content-end align-self-end">
//               <Box className="info text-right">
//                 <span>{t("responsible person-s")}</span>
//                 <p className="text-capitalize">
//                   {user ? user.firstName + " " + user.lastName : "Anonymous"}
//                 </p>
//                 {user && <a href={`mailto:${user.email}`}>{user.email}</a>}
//               </Box>
//             </Box>
//           ) : (
//             <Box style={{ display: "grid", justifyContent: "right" }}>
//               <Box className="date mb-2">
//                 <span>{t("done on")}</span>
//                 <p>{moment(finishedDate).format("DD.MM.YYYY, HH:MM")}</p>
//               </Box>
//               <Box className="info">
//                 <span>{t("done by")}</span>
//                 <p className="text-capitalize">
//                   {finishedBy.firstName + " " + finishedBy.lastName}
//                 </p>
//               </Box>
//             </Box>
//           )}
//         </Box>
