import "./data-table.component.scoped.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { TableProps } from "../../../types/table-props";
import { TableContentComponent } from "../table-content/table-content.component";
import CasesFilterComponent from "../../cases-filter/cases-filter.component";
import { TableSearchColumn } from "../../../types/table-column";
// import { icons } from "../../../statics/icons";

export const DataTableComponent = (props: TableProps) => {
  const [t] = useTranslation();
  const {
    data,
    options,
    pagination,
    columns,
    sort,
    paginate,
    loading,
    reset,
    title,
    subTitle,
    clickableRow,
    tableName,
    reload,
    // exportData,
    onReload,
    // onExport,
    filter,
    onFilterChange,
    refresh,
  } = props;

  const [internal, setInternal] = useState(
    filter?.group === "internal" ? true : false
  );
  const [external, setExternal] = useState(
    filter?.group === "external" ? true : false
  );
  const [auditor, setAuditor] = useState(
    filter?.role === "auditor" ? true : false
  );

  const tools = {
    sort: (...args: any) => sort(...args),
  };

  const casesSearchColumns: TableSearchColumn[] = [
    { label: t("status"), searchId: "statusId" },
    { label: t("affected company"), searchId: "branchId" },
    { label: t("period"), searchId: "period" },
    { label: t("category"), searchId: "categoryId" },
    { label: t("clerk"), searchId: "assigneeId" },
    { label: t("ROLE"), searchId: "role" },
  ];

  const onFilter = (e: any) => {
    e.persist();
    let attr = "";
    let value = "";
    if (e.target.name === "internal") {
      attr = "group";
      value = e.target.checked ? "internal" : "";
      setInternal(e.target.checked);
    } else if (e.target.name === "external") {
      attr = "group";
      value = e.target.checked ? "external" : "";
      setExternal(e.target.checked);
    } else if (e.target.name === "auditor") {
      attr = "role";
      value = e.target.checked ? "auditor" : "";
      setAuditor(e.target.checked);
    }
    onFilterChange(value, attr);
  };

  useEffect(() => {
    setInternal(filter?.group === "internal" ? true : false);
    setExternal(filter?.group === "external" ? true : false);
    setAuditor(filter?.role === "auditor" ? true : false);
  }, [filter]);

  return (
    <div className="table-wrapper">
      <div className="table-head">
        <div className="d-flex align-items-center flex-wrap">
          <div className="table-title mr-3">{title}</div>
          {subTitle && <div className="table-total mt-1">{subTitle}</div>}
        </div>
        <div className="table-extra">
          {tableName === "users" && (
            <div className="table-filter d-flex mr-3">
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    onChange={onFilter}
                    name="auditor"
                    size="small"
                    checked={auditor}
                    disabled={external || internal}
                  />
                }
                label={t("auditor")}
              />
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    onChange={onFilter}
                    name="internal"
                    size="small"
                    checked={internal}
                    disabled={external || auditor}
                  />
                }
                label={t("intern")}
              />
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    onChange={onFilter}
                    name="external"
                    size="small"
                    checked={external}
                    disabled={internal || auditor}
                  />
                }
                label={t("extern")}
              />
            </div>
          )}

          {reload && (
            <Button
              variant="outlined"
              color="primary"
              className="btn-secondary-theme mr-3 text-normal"
              onClick={onReload}
            >
              <CachedIcon className="mr-2" /> {t("reload list")}
            </Button>
          )}

          {tableName === "cases" && (
            <div className="cases-filters-container mr-3">
              <CasesFilterComponent
                onFiltersChange={onFilterChange}
                searchableFields={casesSearchColumns}
              />
            </div>
          )}

          {/* {exportData && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="btn-primary-theme"
              onClick={onExport}
            >
              <img src={icons.downloadWhite} alt="" className="mr-3" />
              Export
            </Button>
          )} */}
        </div>
      </div>
      <div className="table-container">
        {loading && !data?.length && (
          <div className="loader-area">
            <CircularProgress />
          </div>
        )}
        <TableContentComponent
          options={options}
          data={data}
          columns={columns}
          tools={tools}
          loading={loading}
          reset={reset}
          paginate={paginate}
          pagination={pagination}
          clickableRow={clickableRow}
          refresh={refresh}
          tableName={tableName}
        />
      </div>
    </div>
  );
};
