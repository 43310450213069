import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./tab-content-head.component.scoped.scss";

interface tabContentHeadProps {
  title: string;
  subTitle: string;
  logo?: string;
}

const TabContentHeadComponent = (props: tabContentHeadProps) => {
  const { title, subTitle, logo } = props;
  return (
    <Box className="head">
      <Box>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
        <Typography variant="subtitle2" style={{ color: "var(--grey-text)" }}>
          {subTitle}
        </Typography>
      </Box>
      <img src={logo ? logo : "/icons/create-case/AI-logo.svg"} alt="" />
    </Box>
  );
};

export default TabContentHeadComponent;
